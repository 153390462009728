<template>
  <Menubar />
  <MainContainer>
    <template v-slot:header>
      <button class="a-main-btn" @click="settings.addModal = true">
        Agregar
      </button>
      <div class="a-search-container">
        <InputText placeholder="Buscar" v-model="filters['global'].value" />
      </div>
    </template>
    <DataTable
      :value="builders"
      :paginator="true"
      :rows="8"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink"
      :filters="filters"
      :globalFilterFields="['name', 'email', 'nit']"
    >
      <!-- Id field -->
      <Column field="id" header="#" sortable headerStyle="width: 5rem" />
      <!-- Fields defined in data section -->
      <Column
        v-for="col of fields"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        sortable
      />

      <!-- State field -->
      <Column header="Condición" headerStyle="width: 9rem">
        <template #body="slotProps">
          <Badge
            v-if="slotProps.data.active == true"
            value="Activo"
            severity="success"
          />
          <Badge
            v-if="slotProps.data.active == false"
            value="Inactivo"
            severity="danger"
          />
        </template>
      </Column>

      <!-- Action buttons -->
      <Column headerStyle="width: 9rem">
        <template #body="slotProps">
          <div class="a-container-tab-btns">
            <button
              class="a-icon-tab-btn a-detail-btn"
              @click="detailBuilder(slotProps.data)"
            >
              <img :src="require('@/assets/icons/eye-white.svg')" />
            </button>
            <button
              class="a-icon-tab-btn a-edit-btn"
              @click="editBuilder(slotProps.data)"
              :disabled="!slotProps.data.active"
            >
              <img :src="require('@/assets/icons/tool-white.svg')" />
            </button>
            <button
              class="a-icon-tab-btn a-delete-btn"
              @click="deleteBuilder(slotProps.data)"
              :disabled="!slotProps.data.active"
              v-if="usertype.name == 'Administrador'"
            >
              <img :src="require('@/assets/icons/cross.svg')" />
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </MainContainer>

  <Dialog
    header="Crear constructora"
    v-model:visible="settings.addModal"
    v-if="settings.addModal"
    :modal="true"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '50vw' }"
  >
    <NewBuilder @onSuccess="builderAdded" @onExit="settings.addModal = false" />
  </Dialog>

  <Dialog
    v-model:visible="settings.detailModal"
    v-if="settings.detailModal"
    :modal="true"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '50vw' }"
  >
    <template #header>
      <h3>
        Constructora <span class="a-id-badge">{{ settings.builder.id }}</span>
      </h3>
    </template>
    <DetailBuilder
      :item="settings.builder"
      :isEdit="settings.isEdit"
      @onSuccess="builderEdited"
      @onExit="
        settings.detailModal = false;
        settings.isEdit = false;
      "
    />
  </Dialog>

  <ConfirmDialog />

  <Toast />
</template>

<script>
import Menubar from "@/components/navigation/Top.vue";
import MainContainer from "@/components/secure/MainContainer.vue";
import BuilderAPI from "@/services/builders.js";
import UserAPI from "@/services/users.js";
import NewBuilder from "@/components/builders/NewBuilder.vue";
import DetailBuilder from "@/components/builders/DetailBuilder.vue";
import { FilterMatchMode } from "primevue/api";
export default {
  name: "Builders",
  components: {
    Menubar,
    MainContainer,
    NewBuilder,
    DetailBuilder,
  },
  data() {
    return {
      builders: [],
      fields: [
        {
          field: "name",
          header: "Nombre",
        },
        {
          field: "nit",
          header: "NIT",
        },
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      settings: {
        addModal: false,
        detailModal: false,
        isEdit: false,
        builder: null,
      },
    };
  },
  computed: {
    usertype() {
      if (
        this.$store.state.user != null &&
        this.$store.state.user.userType != null
      ) {
        return this.$store.state.user.userType;
      } else {
        return "Editor";
      }
    },
  },
  methods: {
    getBuilders() {
      BuilderAPI.index()
        .then((response) => {
          this.builders = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getBuilders();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    builderAdded() {
      this.settings.addModal = false;
      this.getBuilders();
      this.$toast.add({
        severity: "success",
        detail: "Se ha añadido un nuevo constructor",
        life: 3000,
      });
    },
    detailBuilder(item) {
      this.settings.builder = item;
      this.settings.detailModal = true;
    },
    editBuilder(item) {
      this.settings.builder = item;
      this.settings.detailModal = true;
      this.settings.isEdit = true;
    },

    builderEdited() {
      this.settings.detailModal = false;
      this.settings.isEdit = false;
      this.settings.builder = null;
      this.getBuilders();
      this.$toast.add({
        severity: "success",
        detail: "Se ha editado una constructora",
        life: 3000,
      });
    },
    deleteBuilder(item) {
      let message = `¿Estás seguro de eliminar la constructora ${item.name}?`;
      this.$confirm.require({
        message: message,
        header: "Eliminar constructora",
        acceptLabel: "Aceptar",
        acceptClass: "p-button-danger",
        rejectLabel: "Cancelar",
        accept: () => {
          //callback to execute when user confirms the action
          BuilderAPI.delete(item)
            .then(() => {
              this.$toast.add({
                severity: "success",
                detail: "Se ha eliminado una constructora",
                life: 3000,
              });
              this.getBuilders();
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response) {
                let response = error.response.data;
                let errors = Object.keys(response);
                let message = "";
                let isTokenError = false;
                errors.forEach((err) => {
                  message += response[err];
                  if (err == "code") {
                    if (response[err] == "token_not_valid") {
                      isTokenError = true;
                      UserAPI.updateToken().then((response) => {
                        sessionStorage.setItem("cfta", response.data.access);
                        this.deleteBuilder(item);
                      });
                    }
                  }
                });
                if (!isTokenError) {
                  this.$toast.add({
                    severity: "error",
                    summary: "Ha ocurrido un error",
                    detail: message,
                    life: 3000,
                  });
                }
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Ha ocurrido un error",
                  detail: error.message,
                  life: 3000,
                });
              }
            });
        },
      });
    },
  },
  mounted() {
    this.getBuilders();
  },
};
</script>

<style scoped></style>
