<template>
  <InputText type="text" v-model="projectType.name" placeholder="Nombre" />
  <Textarea
    v-model="projectType.description"
    :autoResize="true"
    rows="5"
    cols="30"
    placeholder="Descripción"
  />
  <div id="a-row">
    <div class="a-colors-container">
      <div
        v-for="(color, index) in colors"
        :key="index"
        :style="`background: #${color}`"
        class="a-color-button"
        :id="projectType.color == color ? 'a-selected' : 'a-unselected'"
        @click="projectType.color = color"
      ></div>
    </div>
    <InputSwitch v-model="projectType.active" />
  </div>

  <div class="a-buttons">
    <Button
      label="Cancelar"
      class="p-button-raised p-button-danger p-button-text"
      @click="cancel"
    />
    <button
      :class="!isLoading ? 'a-main-btn' : 'a-main-btn a-loading-btn'"
      :disabled="isLoading || !isValid"
      @click="newProjectType"
    >
      Aceptar
    </button>
  </div>
  <Toast />
</template>

<script>
import UserAPI from "@/services/users.js";
import ProjectTypeAPI from "@/services/projecttypes.js";
export default {
  data() {
    return {
      projectType: {
        name: "",
        description: "",
        color: "",
        active: false,
      },
      colors: ["d90024", "fdc30f", "00d986", "2b5ac4", "ca00d9"],
      isLoading: false,
    };
  },
  computed: {
    isValid() {
      return (
        this.projectType.name != "" &&
        this.projectType.description != "" &&
        this.projectType.color != "" &&
        this.projectType.active != ""
      );
    },
  },
  emits: ["onSuccess", "onExit"],
  methods: {
    cancel() {
      this.$emit("onExit");
    },
    newProjectType() {
      this.isLoading = true;
      ProjectTypeAPI.create(this.projectType)
        .then(() => {
          this.$emit("onSuccess");
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.newProjectType();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
  },
};
</script>
