<template>
  <div class="a-content">
    <div class="a-breadcrumb">
      <span>{{ breadcrumb }}</span>
    </div>

    <div class="a-main-content">
      <div class="a-btn-content">
        <slot name="header"></slot>
      </div>
      <slot></slot>
    </div>

    <div id="a-admin-footer">
      <span>
        <strong
          >Desarrollado por la Dirección de Sistemas Tecnológicos e Informáticos
        </strong>
      </span>
      <br />
      <span>
        <strong>Secretaria de las TIC</strong>
        - Villavicencio Cambia Contigo
      </span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    breadcrumb() {
      return this.$route.meta.display;
    },
  },
};
</script>

<style>
.a-btn-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.a-btn-content .a-main-btn {
  max-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.a-btn-content .p-inputtext {
  max-height: 48px;
}

.a-btn-content .a-icon-btn div {
  margin: 0;
}

#a-admin-footer {
  margin: 10px 0;
  font-family: Montserrat;
  font-size: 10px;
  letter-spacing: -0.1px;
  text-align: center;
}
</style>
