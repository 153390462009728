<template>
  <Menubar />
  <MainContainer>
    <template v-slot:header>
      <button class="a-main-btn" @click="settings.addModal = true">
        Agregar
      </button>
      <div class="a-search-container">
        <InputText placeholder="Buscar" v-model="filters['global'].value" />
      </div>
    </template>
    <DataTable
      :value="projectTypes"
      :paginator="true"
      :rows="8"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink"
      :filters="filters"
      :globalFilterFields="['name']"
    >
      <!-- Fields defined in data section -->
      <Column
        v-for="col of fields"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        sortable
      />
      <!-- State field -->
      <Column header="Condición">
        <template #body="slotProps">
          <Badge
            v-if="slotProps.data.active == true"
            value="Activo"
            severity="success"
          />
          <Badge
            v-if="slotProps.data.active == false"
            value="Pendiente"
            severity="danger"
          />
        </template>
      </Column>

      <!-- Color field -->
      <Column header="Color">
        <template #body="slotProps">
          <div
            :style="`background: #${slotProps.data.color}`"
            class="a-tab-color"
          ></div>
        </template>
      </Column>

      <!-- Action buttons -->
      <Column headerStyle="width: 9rem">
        <template #body="slotProps">
          <div class="a-container-tab-btns">
            <button
              class="a-icon-tab-btn a-detail-btn"
              @click="detailProjectType(slotProps.data)"
            >
              <img :src="require('@/assets/icons/eye-white.svg')" />
            </button>
            <button
              class="a-icon-tab-btn a-edit-btn"
              @click="editProjectType(slotProps.data)"
            >
              <img :src="require('@/assets/icons/tool-white.svg')" />
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </MainContainer>

  <ConfirmDialog />

  <Dialog
    header="Añadir estado"
    v-model:visible="settings.addModal"
    v-if="settings.addModal"
    :modal="true"
    :breakpoints="{ '960px': '85vw' }"
    :style="{ width: '50vw' }"
  >
    <NewProjectType
      @onSuccess="projectAdded"
      @onExit="settings.addModal = false"
    />
  </Dialog>

  <Dialog
    v-model:visible="settings.detailModal"
    v-if="settings.detailModal"
    :modal="true"
    :breakpoints="{ '960px': '85vw' }"
    :style="{ width: '50vw' }"
  >
    <template #header>
      <h3>
        Estado <span class="a-id-badge">{{ settings.projectType.id }}</span>
      </h3>
    </template>
    <DetailProjectType
      :item="settings.projectType"
      :isEdit="settings.isEdit"
      @onSuccess="projectTypeEdited"
      @onExit="
        this.settings.detailModal = false;
        this.settings.isEdit = false;
      "
    />
  </Dialog>

  <Toast />
</template>

<script>
import Menubar from "@/components/navigation/Top.vue";
import MainContainer from "@/components/secure/MainContainer.vue";
import ProjectTypesAPI from "@/services/projecttypes.js";
import UserAPI from "@/services/users.js";
import { FilterMatchMode } from "primevue/api";
import NewProjectType from "@/components/projectTypes/NewProjectType.vue";
import DetailProjectType from "@/components/projectTypes/DetailProjectType.vue";

export default {
  name: "ProjectTypes",
  components: {
    Menubar,
    MainContainer,
    NewProjectType,
    DetailProjectType,
  },
  data() {
    return {
      projectTypes: [],
      fields: [
        {
          field: "name",
          header: "Estado",
        },
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      settings: {
        addModal: false,
        detailModal: false,
        projectType: null,
        isEdit: null,
      },
    };
  },
  methods: {
    getProjectTypes() {
      ProjectTypesAPI.index()
        .then((response) => {
          this.projectTypes = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getProjectTypes();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    projectAdded() {
      this.settings.addModal = false;
      this.getProjectTypes();
      this.$toast.add({
        severity: "success",
        detail: "Se ha añadido un nuevo estado",
        life: 3000,
      });
    },

    detailProjectType(item) {
      this.settings.projectType = item;
      this.settings.detailModal = true;
    },

    editProjectType(item) {
      this.settings.projectType = item;
      this.settings.isEdit = true;
      this.settings.detailModal = true;
    },
    projectTypeEdited() {
      this.settings.projectType = null;
      this.settings.isEdit = false;
      this.settings.detailModal = false;
      this.getProjectTypes();
      this.$toast.add({
        severity: "success",
        detail: "Se ha editado un estado",
        life: 3000,
      });
    },
  },
  mounted() {
    this.getProjectTypes();
  },
};
</script>
