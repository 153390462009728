import Axios from "axios";

let http = "https://fisico.gobiernocontigo.com/api/v1/";

export default {
  index() {
    return Axios.get(`${http}user/`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },

  updateToken() {
    return Axios.post(`${http}rest-auth/token/refresh/`, {
      refresh: sessionStorage.getItem("cftr"),
    });
  },

  show(id) {
    return Axios.get(`${http}user/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },

  // update(id, data) {},

  create(data) {
    return Axios.post(`${http}user/`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },

  delete(data) {
    data.is_active = false;
    data.userType = data.userType.id;
    return Axios.put(`${http}user/${data.id}/`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },
};
