<template>
  <InputText type="text" v-model="builder.name" placeholder="Nombre *" />
  <InputText type="text" v-model="builder.address" placeholder="Dirección" />
  <InputText
    type="text"
    v-model="builder.email"
    placeholder="Correo electrónico"
  />
  <InputText type="text" v-model="builder.nit" placeholder="NIT *" />
  <InputText type="text" v-model="builder.phone" placeholder="Teléfono" />
  <InputText type="text" v-model="builder.webpage" placeholder="Página web" />

  <div class="a-buttons">
    <Button
      label="Cancelar"
      class="p-button-raised p-button-danger p-button-text"
      @click="cancel"
    />
    <button
      :class="!isLoading ? 'a-main-btn' : 'a-main-btn a-loading-btn'"
      :disabled="isLoading || !isValid"
      @click="newBuilder"
    >
      Aceptar
    </button>
  </div>
  <Toast />
</template>

<script>
import UserAPI from "@/services/users.js";
import BuilderAPI from "@/services/builders.js";
export default {
  name: "NewBuilder",
  data() {
    return {
      builder: {
        name: "",
        address: "",
        email: "",
        nit: "",
        phone: "",
        webpage: "",
        active: true,
      },
      isLoading: false,
    };
  },
  computed: {
    isValid() {
      return this.builder.name != "" && this.builder.nit != "";
    },
  },
  emits: ["onSuccess", "onExit"],
  methods: {
    cancel() {
      this.$emit("onExit");
    },
    newBuilder() {
      this.isLoading = true;

      var form_data = new FormData();
      for (var key in this.builder) {
        form_data.append(key, this.builder[key]);
      }

      BuilderAPI.create(form_data)
        .then(() => {
          this.$emit("onSuccess");
        })

        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.newBuilder();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
  },
};
</script>

<style>
.p-dialog .p-dialog-content .p-inputtext {
  margin-bottom: 2px;
}
</style>
